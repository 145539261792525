<template>
  <b-card>
    <b-row>
      <b-modal
        id="modal-warning"
        ok
        cancel
        ok-variant="warning"
        ok-title="Accept"
        modal-class="modal-warning"
        centered
        title="Program"
        @ok="storeReadyNotification()"
      >
        <b-card-text>
          Are you sure you want to program these notifications?
        </b-card-text>
      </b-modal>
      <b-modal
        id="modal-default-send"
        ok-variant="outline-success"
        scrollable
        :title="accTitle"
        size="xl"
        :hide-footer="true"
        ok-only
        ok-title="Close"
      >
        <div class="day-group">
          <div class="demo-inline-spacing days-div">
            <div>Filter by days:</div>
            <br />
            <span
              class="day-span"
              v-for="day in weekDays"
              :key="day.value"
              @click="showDayNotifs(day.value)"
            >
              <b-avatar class="day-avatar" variant="primary">{{
                day.text
              }}</b-avatar>
            </span>
          </div>
        </div>
        <b-table
          :items="accNotifications"
          :fields="tableFields"
          :thead-class="'text-center'"
        >
          <template #cell(name)="data">
            {{ data.value }}
          </template>
          <template #cell(notifications)="data">
            <div>
              <div>
                <ul class="appNotif">
                  <li
                    v-for="(notification, index) in data.item.notifications"
                    :key="index"
                  >
                    <div class="d-flex justify-content-between">
                      <div>
                        <strong>{{ "Notif Number : " }}</strong>
                        {{ index + 1 }} <br />
                        <strong>{{ "Title : " }}</strong>
                        {{ notification.notification_title }} <br />
                        <strong>{{ "Content : " }}</strong>
                        {{ notification.notification_content }} <br />
                        <span v-if="notification.notification_link">
                          <strong>{{ "Notif Link : " }}</strong>
                          {{ notification.notification_link }} <br />
                        </span>
                        <strong>{{ "Notif time : " }}</strong>
                        {{ notification.notif_time }} <br />
                      </div>
                      <div>
                        <feather-icon
                          v-if="notification.status === 'ready'"
                          color="white"
                          icon="ClockIcon"
                          v-b-tooltip.hover
                          title="this notif is ready to program"
                          size="25"
                        />
                        <feather-icon
                          v-if="notification.status === 'programmed'"
                          color="orange"
                          icon="ClockIcon"
                          v-b-tooltip.hover
                          title="this notif is programmed"
                          size="25"
                        />
                        <feather-icon
                          v-if="notification.status === 'sent'"
                          icon="CheckIcon"
                          size="25"
                          v-b-tooltip.hover
                          title="this notif is sent"
                          color="#1ca350"
                        />
                        <div v-if="notification.status === 'expired'">
                          <feather-icon
                            icon="AlertTriangleIcon"
                            size="25"
                            v-b-tooltip.hover
                            title="this notif time is passed"
                            color="#A90202"
                          />
                        </div>
                        <div
                          class="d-flex"
                          style="gap: 10px"
                          v-if="notification.status === 'error'"
                        >
                          <feather-icon
                            icon="AlertTriangleIcon"
                            size="25"
                            v-b-tooltip.hover
                            :title="notSentError"
                            color="#A90202"
                          />
                          <feather-icon
                            v-if="today"
                            icon="InfoIcon"
                            size="25"
                            v-b-tooltip.hover
                            title="this notif programmed To Resent. Note: 'Just If problem in credential fix it'"
                            color="orange"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </b-table>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="confirmProgram()"
        >
          <feather-icon icon="ClockIcon" class="ml-25" />
          &nbsp;
          <span>Program this notification(s) </span>
        </b-button>
      </b-modal>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(programNotif)="data">
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="flat-info"
              class="btn-icon rounded-circle"
              v-b-tooltip.hover
              title="Program"
              @click="popProgramNotif(data.item)"
              :disabled="clicked.includes(data.item.id)"
            >
              <feather-icon icon="ClockIcon" size="25" />
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col cols="6">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-25"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardText,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormTextarea,
  BFormTimepicker,
  BSpinner,
  BFormCheckbox,
  BAlert,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { heightTransition } from "@core/mixins/ui/transition";
import axios from "@axios";
import axioss from "axios";
import store from "@/store";
import router from "@/router";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import vSelect from "vue-select";
import { BRow, BCol, BCalendar, BTime } from "bootstrap-vue";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import index from "vue-prism-component";

export default {
  components: {
    BFormCheckbox,
    BFormTimepicker,
    vSelect,
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    Prism,
    BCalendar,
    BTime,
    BForm,
    BFormTextarea,
    BSpinner,
    BAlert,
  },
  notSentError: "",
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      tableFields: [
        { key: "name", label: "App Name" },
        {
          key: "notifications",
          label: "Notifications",
        },
      ],

      accounts: [],
      weekDays: [
        { text: "D1", value: 1 },
        { text: "D2", value: 2 },
        { text: "D3", value: 3 },
        { text: "D4", value: 4 },
        { text: "D5", value: 5 },
        { text: "D6", value: 6 },
        { text: "D7", value: 7 },
      ],
      mon: false,
      status_ntf: null,
      account_name: null,
      account_link: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      today: false,
      status_notif: [],
      programmed_notif: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      notifIdToDelete: null,
      programData: [],
      fields: [
        {
          key: "account_name",
          label: "Account name",
        },
        "programNotif",
      ],

      items: [],
      clicked: [],
      thisAccountId: null,
      accNotifications: null,
      backup: null,
      dayNotif: 1,
      accTitle: null,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  created() {
    this.showAllAccounts();
    this.clicked = [];
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    confirmProgram() {
      this.$root.$emit("bv::show::modal", "modal-warning");
    },
    showDayNotifs(day) {
      this.dayNotif = day;
      var element = document.getElementsByClassName("day-avatar")[day - 1];
      this.daysAreFiltered = true;
      var dayAvatars = document.getElementsByClassName("day-avatar");
      dayAvatars.forEach((dayAvatar) => {
        dayAvatar.parentElement.classList.remove("day-active");
      });
      element.parentElement.className += " day-active";
      this.appNotifications = [];
      var notifications;
      notifications = this.backup.filter((e) => e.notif_day === day);
      this.accNotifications = notifications;

      const uniqueApps = notifications.reduce((unique, notification) => {
        const index = unique.findIndex(
          (app) => app.name === notification.title
        );

        let status = "ready";

        var now = new Date();
        var nowDateTime = now.toISOString();
        var nowDate = nowDateTime.split("T")[0];
        if (notification.notif_day === now.getDay()) {
          this.today = true;
        } else {
          this.today = false;
        }
        var target = new Date(nowDate + "T" + notification.notif_time);
        
        
          if (
            target.getTime() <= now.getTime() &&
            notification.notif_day === now.getDay()
          ) {
            status = "expired";
          }
        const matchingStatus = this.status_notif.find((status) => {
          return status.notif_id === notification.notif_id;
        });
        if (matchingStatus) {
          const now = new Date();
          let date = new Date(matchingStatus.date_notif);

          if (
            matchingStatus.successfully_sent === 0 &&
            matchingStatus.error != null
          ) {
            status = "error";
            this.notSentError = `Error : ${matchingStatus.error}`;
          }
          if (matchingStatus.successfully_sent === 1) {
            status = "sent";
          }
          if (
            date < now &&
            matchingStatus.successfully_sent === 0 &&
            matchingStatus.notif_day <= now.getDay()
          ) {
            status = "error";
            // this.notSentError = "this notif time is passed";
            if (matchingStatus.error != null) {
              this.notSentError = `Error : ${matchingStatus.error}`;
            }
          }
          if (
            this.programmed_notif.find(
              (x) => x.notif_id === notification.notif_id
            )
          ) {
            status = "programmed";
          }
       
        }

        notification.status = status;

        if (index === -1) {
          unique.push({
            name: notification.title,
            notifications: [notification],
          });
        } else {
          unique[index].notifications.push(notification);
        }
        return unique;
      }, []);

      this.accNotifications = uniqueApps;
    },
    async showAllAccounts() {
      this.$store
        .dispatch("appsManager/getAllAccounts")
        .then(({ data }) => {
          data.accounts.forEach((account) => {
            axios
              .post("/get-onesignal-accounts", {
                account_id: account.id,
              })
              .then((response) => {
                if (response.data.onesignal > 0) {
                  this.accounts.push({
                    title: account.account_name,
                    value: account.id,
                  });
                  this.items.push(account);
                }
              })
              .catch(console.error);
          });
        })
        .catch(console.error);
    },
    popProgramNotif(item) {
      var currentDate = new Date();
      this.thisAccountId = item.id;
      this.accTitle = "Send notifications of " + item.account_name;
      this.$root.$emit("bv::show::modal", "modal-default-send");
      this.popAccountNotifs(currentDate.getDay());
    },
    storeReadyNotification() {
      let notifications = this.accNotifications;
      if (this.accNotifications.length === 0) {
        this.showToast(
          "warning",
          "AlertOctagonIcon",
          "Alert",
          `this Account not have notifications in day : ${this.dayNotif}`
        );
        return;
      }
      let formattedNotifications = notifications.map((notification) => {
        return {
          notifications: notification.notifications.map((n) => {
            return {
              notif_id: n.notif_id,
              appId: n.appId,
              account_id: n.account_id,
              notif_time: n.notif_time,
              notif_day: n.notif_day,
            };
          }),
        };
      });
      axios
        .post("/store-program-ntf", {
          notifications: formattedNotifications,
        })
        .then((response) => {
          if (response.data.status === "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (response.data.status === "success") {
            this.showToast(
              "success",
              "SaveIcon",
              "Done",
              response.data.message
            );
            this.popAccountNotifs(this.dayNotif);
          } else if (response.data.status === "warning") {
            this.popAccountNotifs(this.dayNotif);
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              response.data.message
            );
          } else {
            
          }
        })
        .catch(console.error);
    },
    popAccountNotifs(day) {
      axios
        .post("/getNotifsByAccount", {
          account_id: this.thisAccountId,
        })
        .then(({ data }) => {
          
          // this.accNotifications = data[0];
          this.backup = data[0];
          this.status_notif = data.status_notif;
          this.programmed_notif = data.Programmed_notif;
        })
        .then(() => {
          this.showDayNotifs(day);
          // this.objApp = this.accNotifications;
        })
        .catch(console.error);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.sentIcon {
  height: 33px;
  width: 33px;
  stroke: $success;
}

.notSentIcon {
  height: 33px;
  width: 33px;
  stroke: $warning;
}

.status {
  display: flex;
  flex-direction: column !important;
  position: relative;
  text-align: center;
}

.feathers {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  display: inline-block;
}

.daysFilter {
  font-size: 0.9em;
}

.day-avatar:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.day-buttons {
  font-size: 1em !important;
}

.buttons-box {
  display: flex;
  flex-direction: row;
  margin: 0 0 25px 0;
  align-items: center;
  justify-content: center;
}

.pull-up {
  width: 45px !important;
  height: 35px !important;
}

.day-active {
  cursor: pointer;
  transform: scale(1.25);
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
  border: solid 2px #d5a419;
  border-radius: 50%;
}

.day-group {
  padding-bottom: 8px;
}

.days-div {
  width: fit-content !important;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.appNotif {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.appNotif li {
  list-style: none;
  width: 300px !important;
}

.num-avatar {
  margin-left: -35px;
  margin-right: 5px;
  cursor: pointer;
  // top: 0;
}
</style>
